@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.about-sec {
  margin-top: 200px;
  @include screen-max-width-1399 {
    margin-top: 120px;
  }
  @include screen-max-width-991 {
    margin-top: 80px;
  }
  @include screen-max-width-576 {
    margin-top: 60px;
  }

  .about-cnt {
    margin-top: 40px;
    gap: 40px;
    @include screen-max-width-1200 {
      gap: 30px;
      margin-top: 30px;
    }
    @include screen-max-width-991 {
      flex-direction: column;
      gap: 40px;
    }

    .about-info {
      width: 50%;
      @include screen-max-width-1200 {
        max-width: 430px;
      }
      @include screen-max-width-991 {
        max-width: 100%;
      }

      .about-sticky {
        position: sticky;
        top: 85px;
        width: 100%;
      }

      .about-photo {
        overflow: hidden;
        width: 100%;
        border-radius: 20px;
        transition: 0.3s;
        @include screen-max-width-1200 {
          border-radius: 10px;
        }

        img {
          border-radius: 20px;
          width: 100%;
          transition: 0.3s;
          @include screen-max-width-1200 {
            border-radius: 10px;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      h3 {
        font-size: 24px;
        line-height: 28px;
        color: $black;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 25px;
        @include screen-max-width-1200 {
          font-size: 20px;
          line-height: 24px;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
}


.card-information-content {
  position: fixed;
  width: 50%;
  background-color: $white;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 40px 20px;
  transition: 0.3s;
  z-index: 155;
  right: 0;
  top: 0;
  transform: translateX(100%);

  @include screen-max-width-991 {
    width: 80vw;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }


  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $blue;
  }


  &.active {
    transform: translateX(0);
  }

  .card-information-title {
    gap: 20px;
    margin-bottom: 20px;

    h3 {
      font-size: 26px;
      max-width: 600px;
    }

    @include screen-max-width-767 {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }

  .card-information-body {
    margin-top: 30px;

    p {
      font-size: 20px;
      @include screen-max-width-991 {
        font-size: 18px;
      }
      @include screen-max-width-576 {
        font-size: 16px;
      }
    }
  }

  .card-information-slider {
    height: 455px;

    @include screen-max-width-1200 {
      height: 300px;
    }
    @include screen-max-width-767 {
      height: 200px;
    }
    @include screen-max-width-576{
      height: 140px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

    }
  }

  .card-information-content-header {
    width: 100%;
    background-color: rgba(169, 169, 169, 0.32);
    overflow: hidden;
    border-radius: 24px;
    @include screen-max-width-991{
      border-radius: 12px;
    }

  }

  .gallery-slider-navigations {
    display: flex;
    align-items: center;
    gap: 20px;
    @include screen-max-width-576 {
      gap: 12px;
    }

    .slideNext-btn, .slidePrev-btn {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(230, 230, 230, 1);
      border-radius: 8px;
      cursor: pointer;
      color: rgba(79, 77, 85, 1);

      svg {
        width: 16px;
      }

      @include screen-max-width-1200 {
        svg {
          width: 10px;
        }
      }
      @include screen-max-width-576 {
        width: 32px;
        height: 32px;
        svg {
          width: 10px;
        }
      }

    }
  }
}

.card-information-bg {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.card-information-video {
  width: 100%;
  height: 300px;
  margin: 30px 0;

  iframe {
    width: 100%;
    height: 100%;
  }

}
