@import "src/assets/style/variables";
@import "src/assets/style/mixin";


.company-sec {
  margin-top: 180px;
  @include screen-max-width-1399 {
    margin-top: 120px;
  }
  @include screen-max-width-991 {
    margin-top: 80px;
  }

  .company-cnt {
    margin-top: 40px;
    gap: 50px;
    margin-bottom: 40px;
    @include screen-max-width-991 {
      gap: 35px;
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 20px;
    }

    .company-photo {
      display: flex;
      max-width: 750px;
      width: 100%;
      @include screen-max-width-991 {
        max-width: 100%;
      }

      img {
        border-radius: 15px;
        width: 100%;
        object-fit: cover;

        @include screen-max-width-1200 {
          border-radius: 15px;
        }
        @include screen-max-width-991 {
          max-height: 400px;
        }
      }
    }

    .company-info {
      max-width: 600px;
      width: 100%;

      @include screen-max-width-991 {
        max-width: 650px;
      }

      h2 {
        font-size: 34px;
        font-weight: 500;
        color: $black;
        line-height: 44px;

        @include screen-max-width-991 {
          font-size: 22px;
          line-height: 28px;
        }
        @include screen-max-width-576{
          font-size: 20px;
        }
      }

      p {
        font-weight: 400;
        color: $black;
        font-size: 20px;
        line-height: 28px;
        margin-top: 25px;

        @include screen-max-width-1200 {
          font-size: 16px;
          margin-top: 15px;
        }
      }
    }
  }
}
