@import "src/assets/style/variables";
@import "src/assets/style/mixin";


.partners-sec{
  margin-top: 200px;
  padding: 0 20px;
  .swiper-container-free-mode > .swiper-wrapper{
    transition-timing-function : linear;
  }
  @include screen-max-width-1399{
    margin-top: 120px;
  }
  @include screen-max-width-991{
    margin-top: 80px;
  }
  .partners-slider{
    margin-top: 110px;
    @include screen-max-width-1399{
      margin-top: 80px;
    }
    @include screen-max-width-1200{
      margin-top: 60px;
    }
    @include screen-max-width-991{
      margin-top: 40px;
    }
    .partners-logo{
      display: flex;
      height: 80px;
      width: auto;
      margin: 0 auto;
      justify-content: center;
      img{
        object-fit: contain;
      }
    }
  }
}

.swiper-container-free-mode {
  .swiper-wrapper {
    transition-timing-function: linear;
  }

}
