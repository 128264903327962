@import "./variables";
@import "./mixin";


.G-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.G-hidden {
  overflow: hidden;
}

.G-container {
  max-width: 1450px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

h2 {
  font-size: 40px;
  line-height: 44px;
  color: #000000;
  text-transform: uppercase;

  @include screen-max-width-576 {
    font-size: 30px;
  }

}


.G-animation {
  opacity: 0;
  visibility: hidden;
  transition: 1s all;
  transform: translateY(-10px);

  &.G-show-element {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(0);
  }

}


