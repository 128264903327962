@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.banner-sec {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;


  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .separation-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
      //background-color: transparent;
    }
  }

  .banner-cnt {
    height: 100vh;
    min-height: 600px;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 11;
    @include screen-max-width-991 {
      min-height: 500px;
      padding: 30px 0;
      padding-top: 80px;

    }

    h1 {
      color: $white;
      font-size: 60px;
      text-transform: uppercase;
      opacity: 0;
      visibility: hidden;

      @include screen-max-width-1200 {
        font-size: 38px;
      }
      @include screen-max-width-991 {
        font-size: 30px;
      }
    }

    p {
      margin-top: 30px;
      color: $white;
      font-size: 28px;
      margin-bottom: 20px;
      line-height: 34px;
      max-width: 650px;
      width: 100%;
    ;
      @include screen-max-width-1399 {
        font-size: 20px;
        line-height: 28px;
        max-width: 550px;
      }
      @include screen-max-width-991 {
        font-size: 18px;
        line-height: 24px;
        max-width: 500px;
      }
    }
  }
}
