@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.about-works {
  cursor: pointer;
  width: 100%;
  gap: 40px;
  @include screen-max-width-1399 {
    gap: 24px;
  }
  @include screen-max-width-1200 {
    gap: 12px;
  }
  @include screen-max-width-991 {
    gap: 20px;
  }
  @include screen-max-width-576 {
    gap: 40px;
  }



  .about-works-item {
    width: calc(50% - 20px);

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    @include screen-max-width-1200 {
      width: calc(50% - 6px);
    }
    @include screen-max-width-991 {
      width: calc(50% - 10px);
    }
    @include screen-max-width-576 {
      width: 100%;
    }

    .about-works-photo {
      border-radius: 20px;
      width: 100%;
      overflow: hidden;
      transition: 0.3s;
      height: 380px;
      @include screen-max-width-1200 {
        border-radius: 10px;
      }
      @include screen-max-width-991{
        height: 300px;
      }
      @include screen-max-width-767{
        height: 200px;
      }

      img {
        border-radius: 20px;
        width: 100%;
        transition: 0.3s;
        height: 100%;
        object-fit: cover;
        @include screen-max-width-1399 {
          border-radius: 15px;
        }
        @include screen-max-width-1399 {
          border-radius: 10px;
        }
      }


    }

    h3 {
      font-size: 24px;
      color: $black;
      font-weight: 500;
      margin-top: 20px;
      @include screen-max-width-1200 {
        font-size: 20px;
        margin-top: 15px;
      }
      @include screen-max-width-576 {
        font-size: 20px;
        margin-top: 10px;
      }
    }

    p {
      display: inline-block;
      padding: 10px 0;

      border-bottom: 1px solid $blue;
    }
  }
}
