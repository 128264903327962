@import "src/assets/style/variables";
@import "src/assets/style/mixin";

footer {
  background: $black;
  padding: 30px 0;

  .footer-main {
    position: relative;
    gap: 30px;

    @include screen-max-width-767 {
      flex-direction: column;
      text-align: center;
    }

    .footer-logo {
      width: 300px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .footer-soc {

      ul {
        gap: 20px;
        @include screen-max-width-767 {
          gap: 10px;
        }

        li {
          display: flex;
          cursor: pointer;
        }
      }
    }

    .footer-phone {

      .footer-links {
        gap: 20px;
        @include screen-max-width-767{
          align-content: center;
          justify-content: center;
        }
      }

      a {
        color: $white;
        font-size: 18px;
        font-weight: 500;
        display: block;
        margin-bottom: 15px;
        @include screen-max-width-767 {
          font-size: 16px;
        }
      }
    }
  }

  .footer-politic {
    margin-top: 30px;
    @include screen-max-width-767 {
      margin-top: 20px;
    }

    span {
      color: $white;
      font-size: 20px;
      text-transform: uppercase;
      cursor: pointer;
      @include screen-max-width-1200 {
        font-size: 18px;
      }
      @include screen-max-width-767 {
        font-size: 16px;
      }
    }
  }
}
