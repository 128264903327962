@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.charity-sec {
  margin-top: 120px;
  padding: 40px 0;
  @include screen-max-width-1200 {
    margin-top: 80px;
  }
  @include screen-max-width-991 {
    margin-top: 60px;
  }
  @include screen-max-width-576 {
    margin-top: 40px;
  }

  .charity-cnt {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .charity-gallery {
      width: 100%;

      .charity-slider {
        border-radius: 15px;

        .charity-photo {
          img {
            width: 100%;
            object-fit: cover;
            border-radius: 15px;
            height: 600px;
            @include screen-max-width-991 {
              height: 400px;
            }
            @include screen-max-width-576 {
              height: 200px;
            }

          }
        }
      }
    }

    .charity-info {
      width: 100%;
      h2{
        @include screen-max-width-767{
          font-size: 26px;
        }
      }

      p {
        margin-top: 24px;
        font-size: 18px;
        color: $black;
        font-weight: 400;
        line-height: 28px;
        @include screen-max-width-576 {
          font-size: 16px;
        }
      }


    }
  }
}

//17 dari ekexecia katerel en veranorqman ashxatanqner pexumneri arcunqum haytnabervel en xachqarery