@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.advantages-sec {
  margin-top: 200px;
  @include screen-max-width-1399 {
    margin-top: 120px;
  }
  @include screen-max-width-991 {
    margin-top: 80px;
    h2{
      font-size: 26px;
    }
  }

  .advantages-text {
    margin-top: 60px;
    @include screen-max-width-991 {
      margin-top: 30px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 60px;
      list-style: none;
      li {
        width: calc(50% - 30px);
        @include screen-max-width-991 {
          width: 100%;
        }

        h3 {
          font-size: 24px;
          color: $black;
          font-weight: bold;
          @include screen-max-width-1200 {
            font-size: 20px;
          }
          @include screen-max-width-576 {
            font-size: 18px;
            width: 100%;
          }
        }

        p {
          margin-top: 10px;
          font-size: 18px;
        }

      }
    }
  }
}

