@import "src/assets/style/variables";
@import "src/assets/style/mixin";


.gallery-sec{
  margin-top: 220px;
  margin-bottom: 180px;
  @include screen-max-width-1399{
    margin-top: 120px;
    margin-bottom: 100px;
  }
  @include screen-max-width-991{
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .gallery-cnt{
    margin-top: 60px;
    gap: 30px 20px;
    @include screen-max-width-1399{
      margin-top: 40px;
      gap: 20px;
    }
    @include screen-max-width-991{
      margin-top: 30px;
      gap: 10px;
    }

    .gallery-photo{
      transition: 0.3s;
      width: calc(33% - 20px);
      cursor: pointer;
      overflow: hidden;
      border-radius: 20px;
      @include screen-max-width-1399{
        width: calc(33% - 11px);
      }
      @include screen-max-width-991{
        width: calc(50% - 5px);
      }

      img{
        border-radius: 20px;
        width: 100%;
        transition: 0.3s;
        object-fit: cover;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
