@import "src/assets/style/variables";
@import "src/assets/style/mixin";


header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  border-bottom: 1px solid $white;
  transition: 0.3s;

  .header-main {
    padding: 20px 0;
    transition: 0.3s;
    @include screen-max-width-1200 {
      padding: 20px 0;
    }

    .header-logo {
      width: 150px;
      height: 60px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @include screen-max-width-991{
        width: 100px;
        height: 40px;
      }

    }

    .header-menu {
      @include screen-max-width-991 {
        position: fixed;
        width: 300px;
        top: 0;
        right: 0;
        background-color: $black;
        height: 100vh;
        z-index: 111;
        padding: 80px 20px 20px;
        transform: translateX(300px);
        transition: 0.3s;

        &.open-menu {
          transform: translateX(0);
        }
      }

      ul {
        gap: 40px;
        @include screen-max-width-1399 {
          gap: 20px;
        }


        @include screen-max-width-991 {
          flex-direction: column;
          align-items: flex-end;
          gap: 30px;
        }

        li {
          font-size: 22px;
          text-transform: uppercase;
          font-weight: 500;
          transition: 0.3s;
          color: $white;
          cursor: pointer;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            width: 0;
            height: 2px;
            background-color: $white;
            transition: 0.3s;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }

          @include screen-max-width-1399 {
            font-size: 18px;
          }
          @include screen-max-width-1399 {
            font-size: 16px;
          }
        }
      }

    }

    .header-lang {
      gap: 30px;

      @include screen-max-width-991{
        gap: 20px;
      }

      .header-phone {
        color: $white;
        width: 20px;
        max-width: 20px;
      }

      ul {
        gap: 30px;
        @include screen-max-width-991 {
          gap: 10px;
        }
        li {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 500;
          transition: 0.3s;
          cursor: pointer;
          color: $white;
          @include screen-max-width-767 {
            font-size: 18px;
          }
        }
      }
    }

    .header-bg {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10;
      transition: 0.3s;

      &.active-bg {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.header-scroll {
  background: rgba(0, 0, 0, 0.6);

  .header-main {
    padding: 20px 0;
  }
}


.toggle-menu {
  display: none;
  width: 30px;
  height: 14px;
  flex-direction: column;
  justify-content: space-between;
  @include screen-max-width-991 {
    display: flex;
  }
  position: relative;
  z-index: 999;

  span {
    width: 100%;
    height: 2px;
    background-color: $white;
    display: block;
    transition: 0.3s;

  }

  &.open-burger {
    justify-content: center;
    align-items: center;

    span {
      &:first-child {
        transform: rotate(45deg) translateX(2px);
      }

      &:last-child {
        transform: rotate(-45deg) translateX(1px);
      }
    ;
    }
  }
}

.mobile-languages{
  display: flex;
  margin-top: 40px;
  flex-direction: row !important;
  justify-content: flex-end;
}

