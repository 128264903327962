@import "src/assets/style/variables";
@import "src/assets/style/mixin";

.work-sec {
  margin-top: 180px;
  @include screen-max-width-1399 {
    margin-top: 120px;
  }
  @include screen-max-width-991 {
    margin-top: 80px;
    h2{
      font-size: 26px;
    }
  }

  .work-cnt {
    margin-top: 50px;
    gap: 60px;
    @include screen-max-width-1399 {
      margin-top: 40px;
      gap: 40px;
    }
    @include screen-max-width-1200 {
      margin-top: 30px;
      gap: 30px;
    }
    @include screen-max-width-991 {
      flex-direction: column;
      gap: 30px;
      align-items: flex-start;
      &:last-child {
        flex-direction: column-reverse;
      }

    }

    .work-info {
      max-width: 600px;
      width: 100%;
      @include screen-max-width-1200 {
        max-width: 450px;
      }
      @include screen-max-width-991 {
        max-width: 750px;
      }

      h2 {
        font-size: 34px;
        font-weight: 500;
        color: $black;
        line-height: 44px;
        @include screen-max-width-1200 {
          font-size: 30px;
          line-height: 38px;
        }

        @include screen-max-width-991 {
          font-size: 22px;
          line-height: 28px;
        }
      }

      p {
        font-weight: 400;
        color: $black;
        font-size: 20px;
        line-height: 28px;
        margin-top: 25px;
        @include screen-max-width-1200 {
          font-size: 18px;
          line-height: 24px;
        }
        @include screen-max-width-767{
          font-size: 16px;
        }
      }
    }

    .work-photo {
      display: flex;
      max-width: 700px;
      width: 100%;
      max-height: 400px;
      @include screen-max-width-991 {
        max-width: 100%;
      }

      img {
        object-fit: cover;
        border-radius: 20px;
        width: 100%;
      }
    }
  }
}
